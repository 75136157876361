import { Box,Typography } from '@mui/material';
import React from 'react';

const Progress = ()=>{
    return(
        <Box
            sx={{
                backgroundColor: "yellow",
            }}
        >
            <Typography>
                Progress
            </Typography>
        </Box>
    )
}
export default Progress;