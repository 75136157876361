import React,{useState} from 'react';
import Login from "../components/login_signup/login";
import StudentDashboardComponent from '../student_dashboard/student_dashboard.component'
const DashboardPage = ()=>{
    const[loginToken,setLoginToken] = useState(false);
    return(
        <>
            {
                !loginToken ? <Login setLoginToken={setLoginToken} />:<StudentDashboardComponent setLoginToken={setLoginToken}/>
            }
        </>
    )
}
export default DashboardPage;