import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
const TakeTestButton = ({testStyle})=>{
    console.log("test button style",testStyle)
    return(
        <Link
            to='/student_dashboard'
            target='_blank'
            style={{
                display: 'inline-flex',
                textDecoration: 'none', // Removes any link underlining
                padding: 0,
                margin: 0,
                border: "none", // Remove any default border
                backgroundColor: "transparent"
            }}
        >
            <Button 
                variant="contained" 
                sx={{
                    // backgroundColor: "transparent",
                    color: "accent.secondary",
                    backgroundColor: "primary.second",
                    fontSize: testStyle.fontSize,
                    mt: testStyle.mt,
                    fontWeight: testStyle.fontWeight,
                    textTransform: testStyle.textTransform,
                    letterSpacing: testStyle.letterSpacing,
                    // border: "1px solid black",
                    '&:hover':{
                        backgroundColor:testStyle.backgroundColor,
                    }
                    // borderRadius: "0px"
                }}
            >
                Take Test
            </Button>
        </Link>
    )
}
export default TakeTestButton;