import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import DashboardContent from './dashboard.content';
import ScoreBoard from './scoreboard';
import StudentProgress from './progress';
const NAVIGATION = [
  {
    kind: 'header',
    title: 'Main items',
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'scoreboard',
    title: 'Scoreboard',
    icon: <ScoreboardIcon />,
  },
  {
    segment: 'progress',
    title: 'Progress',
    icon: <TimelineIcon />,
  },
  {
    segment: 'profile',
    title: 'Profile',
    icon: <PersonIcon />,
  },
  {
    segment: 'logout',
    title: 'Logout',
    icon: <LogoutIcon />,
  },
];

const demoTheme = createTheme({
    components: {
      MuiAppProvider: {
        styleOverrides: {
          brandingTitle: {
            backgroundColor: 'lightblue', // Custom default background for the title
            color: 'white',              // Custom text color for the title
            padding: '8px',              // Optional padding to make it look better
          },
        },
      },
    },
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { 
    light: true,
    dark: true
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});
const DashboardComponent = (path)=>{
  switch(path){
    case '/dashboard':
      return <DashboardContent />
    case '/progress':
      return <StudentProgress />
    case '/scoreboard':
      return <ScoreBoard />
    default:
      return <DashboardContent/>
  }
}

function DemoPageContent({ pathname }) {
  return (
    <Box
      sx={{
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        // backgroundColor: "yellow",
        height: "auto"
      }}
    >
      {
        DashboardComponent(pathname)
      }
    </Box>
  );
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardTabs(props) {
  const { window ,setLoginToken} = props;
  console.log("Print props directly:",props.setLoginToken);
  console.log("Print props using window:",window);

  const [pathname, setPathname] = React.useState('/dashboard');
  if(pathname === '/logout'){
    setLoginToken(false);
  }
  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  // Remove this const when copying and pasting into your project.
  const demoWindow = window !== undefined ? window() : undefined;

  return (
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      theme={demoTheme}
      window={demoWindow}
      branding={{
        logo: '',
        title: 'Student Dashboard',
      }}
      
    >
      {/* Pass the title prop here */}
      <DashboardLayout >
        <DemoPageContent pathname={pathname} />
      </DashboardLayout>
    </AppProvider>
  );
}

DashboardTabs.propTypes = {
  window: PropTypes.func,
};

export default DashboardTabs;
