import React from 'react';
import { Box, Typography } from "@mui/material";

const DashboardContent = ()=>{
  const student_name= "Rajesh Kumar Chaudhary";
  return(
    <Box
      sx={{
        backgroundColor: "green",
      }}
    >
      <Typography>Welcome To Sat Test .</Typography>
      <Typography>{student_name}</Typography>
    </Box>
  )
}
export default DashboardContent;