// import { Box, Button } from "@mui/material";

// const Login = ({setLoginToken})=>{
//     const handleLogin = ()=>{
//         setLoginToken(true)
//     }
//     return(
//         <Box>
//             Login Page
//             <Button variant="outlined" color="black" onClick={handleLogin}>Login</Button>
//         </Box>
//     )
// }
// export default Login;

import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Link,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const url = "https://www.bbgrestaurant.com/bbgapi";

const AuthForm = ({ onAuthSubmit }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState({
    admin_name: "",
    admin_contact: "",
    admin_password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    admin_contact: "",
    admin_password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Validation functions
  const validateContact = (contact) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(contact) || /^\d{10}$/.test(contact);
  };

  const validatePassword = (password) => {
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigits = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    if (password.length < minLength) return "Password must be at least 6 characters long.";
    if (!hasUpperCase) return "Password must contain at least one uppercase letter.";
    if (!hasLowerCase) return "Password must contain at least one lowercase letter.";
    if (!hasDigits) return "Password must contain at least one digit.";
    if (!hasSpecialChar) return "Password must contain at least one special character.";

    return "";
  };

  const handlePasswordMatch = () => {
    return formData.admin_password === formData.confirmPassword;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate contact
    if (!validateContact(formData.admin_contact)) {
      setError((prev) => ({
        ...prev,
        admin_contact: "Invalid contact (must be a valid email or 10-digit phone number).",
      }));
      return;
    }

    // Validate password
    const passwordError = validatePassword(formData.admin_password);
    if (passwordError) {
      setError((prev) => ({ ...prev, admin_password: passwordError }));
      return;
    }

    // Check password match if signing up
    if (isSignUp && !handlePasswordMatch()) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match.",
      }));
      return;
    }

    const payload = isSignUp
      ? {
          admin_name: formData.admin_name,
          admin_contact: formData.admin_contact,
          admin_password: formData.admin_password,
        }
      : {
          admin_contact: formData.admin_contact,
          admin_password: formData.admin_password,
        };

    try {
      const endpoint = isSignUp ? `${url}/admin/signup` : `${url}/admin/login`;
      await axios.post(endpoint, payload);

      toast.success(isSignUp ? "Sign up successful. Please log in." : "Login successful.");

      if (isSignUp) {
        setIsSignUp(false);
        setFormData({
          admin_name: "",
          admin_contact: "",
          admin_password: "",
          confirmPassword: "",
        });
      } else {
        onAuthSubmit();
        navigate("/");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    }
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
    setError({ admin_contact: "", admin_password: "", confirmPassword: "" });
  };
  const handleForgetPassword =() =>{
    console.log("check forget password");
  }

  return (
    <Container component="main" maxWidth="xs"
        sx={{
            // backgroundColor:"red",
            color: "black",
            height:'100vh',
            display: 'flex',
            alignItems: 'center'
        }}
    >
      <Paper elevation={3} 
        sx={{ 
            padding: 4, 
            mt: 8,
            // backgroundColor:"primary.second",
            color: "black"
        }}
      
      >
        <Box display="flex" flexDirection="column" alignItems="center"
            sx={{
                color: "black"
            }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.second" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {isSignUp ? "Sign Up" : "Sign In"}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, color:"black"}}>
            {isSignUp && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="admin_name"
                label="Admin Name"
                name="admin_name"
                color="black"
                autoFocus
                value={formData.admin_name}
                onChange={handleInputChange}
              />
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="admin_contact"
              label="Email or Phone"
              name="admin_contact"
              value={formData.admin_contact}
              onChange={handleInputChange}
              error={!!error.admin_contact}
              helperText={error.admin_contact}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="admin_password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="admin_password"
              value={formData.admin_password}
              onChange={handleInputChange}
              error={!!error.admin_password}
              helperText={error.admin_password}
              InputProps={{
                endAdornment: (
                  <Button onClick={handleClickShowPassword}
                    sx={{color:"blue"}}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                ),
              }}
            />
            <Link component="button" onClick={handleForgetPassword} variant="body2"
                style={{
                    color:"gray"
                }}
            >
              {isSignUp ? "" : "Forget Password"}
            </Link>
            {isSignUp && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                error={!!error.confirmPassword}
                helperText={error.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <Button onClick={handleClickShowPassword}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  ),
                }}
              />
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {isSignUp ? "Sign Up" : "Sign In"}
            </Button>
            <Link component="button" onClick={toggleSignUp} variant="body2"
                style={{
                    color:"gray"
                }}
            >
              {isSignUp ? "Already have an account? Sign in" : "Don't have an account? Sign up"}
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default AuthForm;