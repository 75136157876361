import React from 'react';
import { Container, Fade, Grow, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import SatBgPic from "../../../assets/sat_bg_pic.png"; // Correct path to your image
import TakeTestButton from '../../layout/action_buttons/take_test.buttons';
const HeroSection = () => {
  const testStyle = {
    fontSize: {xs:"14px",md:"20px"},
    fontWeight: "400",
    textTransform: "capitalize",
    letterSpacing: "1px",
    '&:hover':{
        backgroundColor:"primary.second",
    }
  }
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Calculate background size based on scroll position
  const calculateBackgroundSize = () => {
    const baseSize = 100; // Default size in percentage
    const maxSize = 150; // Maximum size in percentage
    const size = baseSize + (scrollPosition / 10); // Adjust size as user scrolls
    return size > maxSize ? maxSize : size;
  };

  return (
    <Container
      maxWidth
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "30vh", sm: "50vh", md: "calc(100vh - 80px)", lg: "calc(100vh - 122px)" },
        backgroundImage: `url(${SatBgPic})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: {xs:"cover",md:`${calculateBackgroundSize()}%`}, // Dynamically calculate background size
        transition: {md:"background-size 0.2s ease-out"}, // Smooth transition on scroll
        // backgroundAttachment: "fixed", // Keep background fixed for parallax effect
      }}
    >
      <Fade in={true} timeout={5000}>
        <Typography variant="h2" fontWeight="700" textAlign="center"
        sx={{
            fontSize: {xs:"18px",sm:"28px",md:"34px",lg:"48px"},
            color: "primary.second"
        }}>
            Achieve Your Dream SAT Score with Sat App
        </Typography>
      </Fade>
      <Grow in={true} timeout={8000}>
        <Typography variant="h5" fontWeight="700" 
        textAlign="center"
            sx={{
                fontSize: {xs:"12px",sm:"18px",md:"22px",lg:"30px"},
                color: "primary.main",
                mb: "14px",
            }}
        >
            Join thousands of students who have improved their SAT scores with our 
            comprehensive test prep platform.<br></br> Sign up today and start your 
            journey to success!
        </Typography>
      </Grow>
      
          {/* <Button
            variant="contained"
            sx={{
                backgroundColor: "primary.second",
                color: "accent.secondary",
                fontSize: {xs:"14px",md:"20px"},
                mt: "14px",
                fontWeight: "400",
                textTransform: "capitalize",
                letterSpacing: "1px",
                '&:hover':{
                    backgroundColor:"primary.second",
                }
            }}
          >
            Take Test
          </Button> */}
          <TakeTestButton testStyle={testStyle}/>
    </Container>
  );
};

export default HeroSection;
